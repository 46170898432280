<template>
  <form v-if="form" class="space-y-6 w-full">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
      <div class="col-span-1">
        <Input
          name="uuid"
          placeholder="UUID"
          v-model="form.uuid"
          :errors="errors.uuid ? errors.uuid : errorMessages.uuid"
        />
      </div>

      <div class="col-span-1">
        <Input
          name="serial_nr"
          placeholder="Sērijas nr."
          v-model="form.serial_nr"
          :errors="
            errors.serial_nr ? errors.serial_nr : errorMessages.serial_nr
          "
        />
      </div>

      <div class="col-span-1">
        <Input
          name="name"
          placeholder="Nosaukums"
          v-model="form.name"
          :errors="errors.name"
        />
      </div>

      <template v-if="item.customer.id !== mainCustomer.id">
        <div class="col-span-1">
          <Select
            v-model="form.owner_branch"
            :items="item.customer_branches"
            :errors="errors.owner_branch"
            placeholder="Filiāle"
          />
        </div>
      </template>

      <template v-if="item.customer.id === mainCustomer.id">
        <div class="col-span-1">
          <Select
            v-model="form.purpose"
            :items="item.purposes"
            :errors="errors.purpose"
            placeholder="Pielietojums"
          />
        </div>
      </template>

      <div class="col-span-1">
        <Input
          name="cost"
          placeholder="Vērtība EUR"
          v-model="form.cost"
          :errors="errors.cost"
        />
      </div>

      <div class="col-span-1">
        <Input
          name="location"
          placeholder="Atrašanās vieta"
          v-model="form.location"
          :errors="errors.location"
        />
      </div>

      <div class="col-span-1 flex items-center mt-5">
        <Checkbox text="Aktīvs" v-model="form.is_active" />
      </div>
    </div>

    <div
      class="
        md:flex-shrink-0
        flex
        items-center
        flex-wrap
        ml-auto
        gap-3
        justify-end
        pt-4
      "
    >
      <template v-if="!loading">
        <button
          type="button"
          class="
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-gradient-to-r
            from-button2-from
            to-button2-to
            hover:from-button2-to hover:to-button2-from
            focus:outline-none focus:border-none
            transition
            duration-500
            ease-in-out
          "
          @click.prevent="submit"
        >
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button
        @click.prevent="hideEditItemForm"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          dark:border-gray-500
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-gray-700
          dark:text-gray-300
          bg-white
          dark:bg-gray-750
          hover:bg-gray-50
          dark:hover:bg-gray-770
          focus:outline-none focus:ring-0
        "
      >
        Atcelt
      </button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Select from "@/components/Components/Select";
import Checkbox from "../../../Components/Checkbox";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditInventoryItemDetails",
  components: {
    Input,
    Select,
    Loading,
    Checkbox,
  },
  props: ["item"],
  data: () => ({
    form: null,
  }),
  computed: {
    ...mapGetters({
      mainCustomer: "systemMainCustomer",
      errorMessages: "inventoryErrorMessages",
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        uuid: {
          rules: ["required"],
        },
      };
    },
  },
  methods: {
    setItemName() {
      this.form.name = this.item.catalog_item.name;
    },
    hideEditItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
      this.$store.dispatch("getSingleInventoryItem", {
        uuid: this.$route.params.itemUUID,
      });
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateInventoryItemDetails", {
          id: this.item.id,
          uuid: this.form.uuid,
          name: this.form.name,
          serial_nr: this.form.serial_nr,
          customer_id: this.item.customer.id,
          branch_id: this.form.owner_branch.id,
          purpose_id: this.item.purpose.id,
          location: this.form.location,
          cost: this.form.cost,
          is_active: this.item.is_active,
        });
      } else this.$Progress.fail();
    },
  },
  created() {
    this.form = this.item;
  },
};
</script>

<style>
</style>